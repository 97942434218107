import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiPost } from "../../api/apiPost";
import { apiGet } from "../../api/apiGet";
import formatItems from "../../helpers/formatItems";
import { formatErrorMessage } from "../../helpers/helperFunctions";

export const createQuote = createAsyncThunk(
  "quote/Create",
  async (_data, thunkAPI) => {
    const storeStates = thunkAPI.getState();
    const {
      subOrder: { subOrder },
      items: { items },
      freight,
    } = storeStates;

    try {
      const createdQuote = await apiPost.quote(
        {
          ...subOrder,
          freight: { ...subOrder.freight, ...freight },
        },
        items,
      );
      return createdQuote;
    } catch (err) {
      if (err?.response?.data) {
        return thunkAPI.rejectWithValue({
          axiosError: err,
          customMsg: (
            <div>
              <p>Error saving the Quote.</p>
              {formatErrorMessage(err?.response?.data)}
            </div>
          ),
        });
      }
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error saving the Quote.",
      });
    }
  },
);

export const getQuote = createAsyncThunk("quote/Get", async (id) => {
  const quote = await apiGet.getQuote(id);
  const formattedItems = formatItems(quote.line_items, null, "quote", {
    id: quote.warehouse_id,
    name: quote.warehouse_name,
  });

  return {
    quote,
    formattedItems,
  };
});

/**
 * Function to get all quotes of a customer and company
 *  @param {object} thunkAPI - Redux object
 */
export const getAllQuotes = createAsyncThunk(
  "quote/all",
  async (_parameters, thunkAPI) => {
    const storeStates = thunkAPI.getState();
    const {
      customer: { customerId },
    } = storeStates;

    try {
      const list = await apiGet.quoteSearchById({
        page: 1,
        customerId,
        orderBy: "quote_num",
        sortOrder: "dsc",
      });
      return list.results.map((quote) => quote.quote_num);
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting suborders.",
      });
    }
  },
);

/**
 * Function to search quotes by ID
 * @param {number} quoteId
 * @param {object} thunkAPI - Redux object
 */
export const searchQuotesbyId = createAsyncThunk(
  "quote/searchByID",
  async (quoteId, thunkAPI) => {
    const storeStates = thunkAPI.getState();
    const {
      customer: { customerId },
    } = storeStates;
    try {
      const list = await apiGet.quoteSearchById({
        quoteId,
        page: 1,
        customerId,
        orderBy: "quote_num",
        sortOrder: "dsc",
      });
      return list.results.map((quote) => quote.quote_num);
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting suborders.",
      });
    }
  },
);

export const getQuoteListByParameters = createAsyncThunk(
  "subOrder/getQuoteListByParameters",
  async (
    { quoteId, page, customerNum, customerName, orderBy, sortOrder, from, to },
    thunkAPI,
  ) => {
    try {
      const resp = await apiGet.quoteSearchById({
        quoteId,
        page,
        customerNum,
        customerName,
        orderBy,
        sortOrder,
        from,
        to,
      });
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting the list of quotes.",
      });
    }
  },
);

export const quoteSlice = createSlice({
  name: "quoteSlice",
  initialState: {
    quoteId: 0,
    allQuotes: [],
    loadingAllQuotes: false,
  },
  reducers: {
    setQuoteObject: () => (state, action) => {
      return {
        ...state,
        quoteObject: action.payload,
      };
    },
    resetQuoteId: (state) => {
      return {
        ...state,
        quoteId: 0,
      };
    },
    setLoadingAllQuotes: (state, action) => {
      return {
        ...state,
        loadingAllSubOrders: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createQuote.fulfilled, (state, action) => {
        state.quoteId = action.payload;
      })

      .addCase(getQuote.fulfilled, (state, action) => {
        state.quoteId = action.payload.quote.quote_num;
      })

      .addCase(getAllQuotes.fulfilled, (state, action) => {
        state.allQuotes = action.payload;
      })
      .addCase(searchQuotesbyId.fulfilled, (state, action) => {
        state.allQuotes = action.payload;
        state.loadingAllQuotes = false;
      });
  },
});

export const { resetQuoteId, setLoadingAllQuotes } = quoteSlice.actions;
export default quoteSlice.reducer;
