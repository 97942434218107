import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { defaultOrderItem } from "../api/models/ItemsModel";
import {
  ORDER_ITEM_NONE_TYPE,
  ORDER_ITEM_INVENTORY_TYPE,
  ORDER_ITEM_DESCRIPTION_TYPE,
  ORDER_ITEM_ADDON_TYPE,
  ITEMS_SECTION_REF,
  FREIGHT_SECTION_REF,
  SUB_ORDER_BOL,
  ORDER_ITEM_XREF_TYPE,
} from "../helpers/const";

import {
  productChangeEvent,
  setOrderItems,
  updateAllItems,
} from "../store/slices/item/itemSlice";
import { setFieldRef, setSectionRef } from "../store/slices/refsSlice";

const itemsType = [
  {
    id: ORDER_ITEM_NONE_TYPE,
    label: "Select Type",
  },
  {
    id: ORDER_ITEM_INVENTORY_TYPE,
    label: "Inventory",
  },
  {
    id: ORDER_ITEM_DESCRIPTION_TYPE,
    label: "Description",
  },
];

function useOrderItems() {
  const [itemType, setItemType] = useState({
    id: ORDER_ITEM_NONE_TYPE,
    label: "Select Type",
  });
  const [allowedActions, setAllowedActions] = useState({
    edit: true,
    add: true,
    addOnRow: false,
    switch: false,
    mt: true,
    wh: false,
    delete: true,
    xref: false,
  });

  const [itemsTypeOptions, setItemsTypeOptions] = useState(
    JSON.parse(JSON.stringify(itemsType)),
  );

  const [xrefOpen, setXrefOpen] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const { fieldRef, sectionRef } = useSelector((state) => state.refs);
  const { items: orderItems } = useSelector((state) => state.items);

  const {
    subOrderId,
    subOrder: { warehouse_name: subOrderWarehouseName, status: subOrderStatus },
    subOrdersLocked,
  } = useSelector((state) => state.subOrder);

  const { savedAsQuote } = useSelector((state) => state.header);
  const { hasItemsErrors, hasWhError } = useSelector(
    (state) => state.validations,
  );

  const {
    customerData: { hasXRefItems },
  } = useSelector((state) => state.customer);

  // Refs
  const typeItemSelector = useRef();

  /**
   * Add a item of type 5 or addon
   */
  function createAddOnRow() {
    if (orderItems.length > 0) {
      dispatch(
        setOrderItems({
          ...defaultOrderItem,
          index: orderItems[orderItems.length - 1].index + 1,
          itemType: ORDER_ITEM_ADDON_TYPE,
          ln: orderItems[orderItems.length - 1].ln + 1,
          productCode: "01320002", // THOMAS IS WORKING ON THIS NOT BEING NEEDED ITS A RANDOM CODE
          description: "************",
          wh: orderItems[orderItems.length - 1].wh,
          d: "Z",
        }),
      );
    }
  }

  /**
   * function add a new row
   * @param {string} type Selected type of item value
   */
  async function addRow(type) {
    setItemType(type);

    const ln = orderItems[orderItems.length - 1].ln + 1;
    const lastWarehouse = orderItems[orderItems.length - 1].wh;

    dispatch(setFieldRef(`item-${ln}`));

    if (type.id === ORDER_ITEM_INVENTORY_TYPE) {
      const index = orderItems[orderItems.length - 1].index + 1;
      dispatch(
        setOrderItems({
          ...defaultOrderItem,
          index: index,
          itemType: type.id,
          ln: ln,
          wh: lastWarehouse,
        }),
      );
    } else if (type.id === ORDER_ITEM_DESCRIPTION_TYPE) {
      const index = orderItems[orderItems.length - 1].index;
      dispatch(
        setOrderItems({
          ...defaultOrderItem,
          isSubItem: true,
          index: index,
          itemType: type.id,
          ln: ln,
          productCode: "Descript",
          wh: lastWarehouse,
          d: "Z",
        }),
      );
    } else if (type.id === ORDER_ITEM_ADDON_TYPE) {
      createAddOnRow();
    } else if (type.id === ORDER_ITEM_XREF_TYPE) {
      setXrefOpen(true);
    }

    setTimeout(() => {
      setItemType({
        id: ORDER_ITEM_NONE_TYPE,
        label: "Select Type",
      });
    }, 100);
  }

  const addXrefItem = async ({ productCode, referenceCode }) => {
    const ln = orderItems[orderItems.length - 1].ln + 1;

    dispatch(
      setOrderItems({
        ...defaultOrderItem,
        productCode,
        index: orderItems[orderItems.length - 1].index + 1,
        itemType: ORDER_ITEM_INVENTORY_TYPE,
        ln,
        wh: orderItems[orderItems.length - 1].wh,
        referenceCode,
      }),
    );

    dispatch(
      productChangeEvent({
        productCode,
        itemType: ORDER_ITEM_INVENTORY_TYPE,
        ln,
      }),
    );
  };

  // Reset the items
  useEffect(() => {
    if (subOrderId === 0) {
      dispatch(updateAllItems([defaultOrderItem]));
    }
  }, [subOrderId]);

  /**
   * Set the allow actions for the items
   */
  useEffect(() => {
    if (subOrderId) {
      setAllowedActions({
        edit: subOrderStatus < 3 ? true : false,
        add: subOrderStatus < 3 ? true : false,
        addOnRow: subOrderStatus > -1 && subOrderStatus < 4 ? true : false,
        switch: false,
        mt: !savedAsQuote && subOrderStatus < 3 ? true : false,
        wh: false,
        delete: !savedAsQuote && subOrderStatus < 3 ? true : false,
        xref: hasXRefItems,
      });
    } else {
      setAllowedActions({
        edit: true,
        add: true,
        addOnRow: false,
        switch: false,
        mt: !savedAsQuote,
        wh: false,
        delete: !savedAsQuote,
        xref: hasXRefItems,
      });
    }
  }, [subOrderStatus, subOrderId, hasXRefItems, savedAsQuote]);

  useEffect(() => {
    if (fieldRef === "addItemSelector") {
      typeItemSelector.current.focus();
    }
  }, [fieldRef]);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter" && event.shiftKey) {
        event.preventDefault();
        if (sectionRef === ITEMS_SECTION_REF) {
          dispatch(setSectionRef(FREIGHT_SECTION_REF));
          dispatch(setFieldRef(SUB_ORDER_BOL));
        }
      }
    };

    if (sectionRef === ITEMS_SECTION_REF) {
      document.addEventListener("keydown", keyDownHandler);
    } else {
      document.removeEventListener("keydown", keyDownHandler);
    }

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [sectionRef]);

  useEffect(() => {
    if (allowedActions.addOnRow) {
      setItemsTypeOptions((prev) =>
        prev.find((item) => item.id === ORDER_ITEM_ADDON_TYPE)
          ? prev
          : [...prev, { id: ORDER_ITEM_ADDON_TYPE, label: "Add-on" }],
      );
    } else {
      setItemsTypeOptions((prev) =>
        prev.filter((item) => item.id !== ORDER_ITEM_ADDON_TYPE),
      );
    }
  }, [allowedActions.addOnRow]);

  useEffect(() => {
    if (allowedActions.xref) {
      setItemsTypeOptions((prev) =>
        prev.find((item) => item.id === ORDER_ITEM_XREF_TYPE)
          ? prev
          : [...prev, { id: ORDER_ITEM_XREF_TYPE, label: "XCrossRef" }],
      );
    } else {
      setItemsTypeOptions((prev) =>
        prev.filter((item) => item.id !== ORDER_ITEM_XREF_TYPE),
      );
    }
  }, [allowedActions.xref]);

  const onClickHandler = (e) => {
    dispatch(setSectionRef(ITEMS_SECTION_REF));
    dispatch(setFieldRef(""));
  };

  return {
    orderItems,
    itemType,
    allowedActions,
    typeItemSelector,
    hasItemsErrors,
    hasWhError,
    subOrderWarehouseName,
    subOrdersLocked,
    itemsTypeOptions,
    xrefOpen,
    addRow,
    onClickHandler,
    setXrefOpen,
    addXrefItem,
  };
}

export default useOrderItems;
