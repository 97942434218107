import axios from "axios";
import { endpoints } from "./endpoints";
import qs from "qs";
import {
  setCookie,
  deleteCookie,
  formatObjectToXML,
} from "../helpers/helperFunctions";

const convert = require("xml-js");

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

export const apiPost = {
  login: login,
  subOrder: createSubOrder,
  lineItem: createLineItem,
  authCookie: updateAuthCookies,
  quote: createQuote,
  saveAsOrder,
  createSalesContactComment,
  sendOrderByEmailFax,
  createUPSPackageQuote,
  createFedExPackageQuote,
};
// example cookie: 'csrftoken=tu5QVbMTPrNhFFYsOmcUwOoC45jPrf0Kds6VqvjPkmNFGxNJqOFJlNtqGdnkojdP; expires=Fri, 11 Aug 2023 03:03:28 GMT; Max-Age=31449600; Path=/; SameSite=Lax'

function updateAuthCookies(username, expirationDays) {
  deleteCookie("username");
  setCookie("username", username, expirationDays);
}

async function login({ username, password, csrfmiddlewaretoken }) {
  const dataString = qs.stringify({
    username: username,
    password: password,
    csrfmiddlewaretoken: csrfmiddlewaretoken,
  });

  const authentication = await axios(
    endpoints.MITCO_API_URL + endpoints.USER_LOGIN,
    {
      method: "post",
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: dataString,
    },
  );
  return authentication;
}

async function createSubOrder(newSubOrder) {
  const {
    freight: {
      billOfLading,
      freightLine,
      freightAmount,
      estimatedCost,
      handlingCharge,
      isDirectShip,
      prepaid,
      blindShip,
      prepaidCode,
      freightRater: { carrierQuoteNumber, TMSQuoteNumber },
      dropShipAddress: {
        id: dropShipAddressId,
        isDropShip,
        name: dropShipAddressName,
        city: dropShipAddressCity,
        route: dropShipAddressRoute,
        state: dropShipAddressState,
        street: dropShipAddressStreet,
        zipCode: dropShipAddressZipCode,
        email: dropShipAddressEmail,
        fax: dropShipAddressFax,
        contactName: dropShipAddressContactName,
        phone: dropShipAddressPhone,
      },
    },
  } = newSubOrder;

  let dropShipInformation = null;

  if (dropShipAddressId === 0 && isDropShip) {
    dropShipInformation = {
      customer_id: newSubOrder.customer_id,
      ship_to_name: dropShipAddressName,
      address: {
        city: dropShipAddressCity,
        route: dropShipAddressRoute,
        state: dropShipAddressState,
        street: dropShipAddressStreet,
        zip_code: dropShipAddressZipCode,
      },
      contact: {
        email: dropShipAddressEmail,
        fax: dropShipAddressFax,
        name: dropShipAddressContactName,
        phone: dropShipAddressPhone,
      },
    };
  }

  const thirdPartyBilling = {
    zip_code: newSubOrder.freight.thirdPartyBilling.zipCode,
    ...newSubOrder.freight.thirdPartyBilling,
  };

  delete thirdPartyBilling.zipCode;

  const data = {
    ...newSubOrder,
    freight: {
      comments: [
        newSubOrder.freight.comments[0],
        newSubOrder.freight.comments[1],
      ],
      bill_of_lading: billOfLading,
      freight_line_id: freightLine ? freightLine.id : 149,
      estimated_cost: estimatedCost,
      freight_amount: +freightAmount,
      handling_charge: +handlingCharge,
      is_direct_ship: isDirectShip,
      prepaid,
      is_drop_ship: isDropShip,
      drop_ship_address_id: dropShipAddressId,
      drop_ship_address: dropShipInformation,
      blind_ship: blindShip,
      prepaid_code: prepaidCode,
      ignore_shipping_rules: false,
      freightline_quote_num: carrierQuoteNumber,
      tms_quote_num: TMSQuoteNumber,
    },
    is_bill_only: newSubOrder.is_bill_only,
    is_stock_transfer: newSubOrder?.is_stock_transfer ? true : false,
    discount_program: {},
    third_party_billing: thirdPartyBilling,
  };

  const response = await axios({
    method: "post",
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFTOKEN",
    withCredentials: true,
    url: endpoints.MITCO_API_URL + "/v1" + endpoints.SUB_ORDER_POST,
    headers: {
      Accept: "application/json",
    },
    data: data,
  });

  return response.data;
}

/**
 * Function to create a new quote
 * @param {object} newQuote - quote to create
 * @param {array} items
 */
async function createQuote(newQuote, items) {
  const newItems = items.reduce((prev, current) => {
    const formattedItem = {
      index: current.index,
      product_code: [2, 4, 5].includes(current.itemType)
        ? null
        : current.productCode,
      product: {
        product_code: [2, 4, 5].includes(current.itemType)
          ? null
          : current.productCode,
        warehouse_name: newQuote.warehouse_name,
      },
      line_num: current.ln,
      item_type: current.itemType,
      description: current.description,
      qty_ordered: current.qrd,
      qty_backordered: current.bo,
      transfer_qty: current.tq,
      discount_schedule: current.d,
      item_price: current.item_price,
      price_type: current.s,
      discount: current.discount,
      po_line_num: 0,
      wheel_id: 0,
      weight: current?.weight ? current.weight : 0,
    };

    if (!current.isSubItem) {
      return [
        ...prev,
        {
          item: formattedItem,
          subitems: [],
        },
      ];
    } else if (current.isSubItem) {
      const parentItem = prev.find((p) => p.item.index === current.index);
      parentItem.subitems.push(formattedItem);

      const newPrev = prev.filter((p) => p.item.index !== current.index);
      newPrev.push(parentItem);

      return newPrev;
    }
  }, []);

  const {
    freight: {
      billOfLading,
      freightLine,
      freightAmount,
      handlingCharge,
      isDirectShip,
      prepaid,
      blindShip,
      estimatedCost,
      prepaidCode,
      freightRater: { carrierQuoteNumber, TMSQuoteNumber },
      dropShipAddress: {
        id: dropShipAddressId,
        isDropShip,
        name: dropShipAddressName,
        city: dropShipAddressCity,
        route: dropShipAddressRoute,
        state: dropShipAddressState,
        street: dropShipAddressStreet,
        zipCode: dropShipAddressZipCode,
        email: dropShipAddressEmail,
        fax: dropShipAddressFax,
        contactName: dropShipAddressContactName,
        phone: dropShipAddressPhone,
      },
    },
  } = newQuote;

  let dropShipInformation = null;

  if (dropShipAddressId === 0 && isDropShip) {
    dropShipInformation = {
      customer_id: newQuote.customer_id,
      ship_to_name: dropShipAddressName,
      address: {
        city: dropShipAddressCity,
        route: dropShipAddressRoute,
        state: dropShipAddressState,
        street: dropShipAddressStreet,
        zip_code: dropShipAddressZipCode,
      },
      contact: {
        email: dropShipAddressEmail,
        fax: dropShipAddressFax,
        name: dropShipAddressContactName,
        phone: dropShipAddressPhone,
      },
    };
  }

  const thirdPartyBilling = {
    zip_code: newQuote.freight.thirdPartyBilling.zipCode,
    ...newQuote.freight.thirdPartyBilling,
  };

  delete thirdPartyBilling.zipCode;

  const dataObject = {
    ...newQuote,
    creditcard_charge: newQuote.freight.creditcard_charge,
    is_stock_transfer: newQuote?.is_stock_transfer ? true : false,
    is_bill_only: newQuote.is_bill_only === "Yes" ? true : false,
    freight: {
      comments: [newQuote.freight.comments[0], newQuote.freight.comments[1]],
      bill_of_lading: billOfLading,
      freight_line_id: freightLine ? freightLine.id : 149,
      freight_amount: +freightAmount,
      handling_charge: +handlingCharge,
      is_direct_ship: isDirectShip,
      prepaid,
      is_drop_ship: isDropShip,
      drop_ship_address_id: dropShipAddressId,
      drop_ship_address: dropShipInformation,
      blind_ship: blindShip,
      prepaid_code: prepaidCode,
      estimated_cost: estimatedCost,
      ignore_shipping_rules: false,
      freightline_quote_num: carrierQuoteNumber,
      tms_quote_num: TMSQuoteNumber,
    },
    line_items: newItems,
    third_party_billing: thirdPartyBilling,
  };

  const response = await axios({
    method: "post",
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFTOKEN",
    withCredentials: true,
    url: endpoints.MITCO_API_URL + "/v1" + endpoints.QUOTE_POST,
    headers: {
      Accept: "application/json",
    },
    data: dataObject,
  });

  if (response.headers?.location) {
    const redirectUrl = response.headers.location;
    const splittedUrl = redirectUrl.split("/");
    return parseInt(splittedUrl[5]);
  }
  return 0;
}

function createLineItem(lineItem, customerOrderNum) {
  const dataObject = {
    product_code: [2, 4, 5].includes(lineItem.itemType)
      ? null
      : lineItem.productCode,
    line_num: lineItem.ln,
    item_type: lineItem.itemType,
    description: lineItem.description,
    qty_ordered: lineItem.qrd,
    qty_backordered: lineItem.bo,
    transfer_qty: lineItem.tq,
    discount_schedule: lineItem.d,
    item_price: lineItem.item_price,
    price_type: lineItem.s,
    discount: lineItem.discount,
    po_line_num: 0,
    wheel_id: 0,
    weight: lineItem?.weight ? lineItem.weight : 0,
  };

  return () =>
    axios.post(
      endpoints.MITCO_API_URL +
        "/v1" +
        endpoints.SUB_ORDER_READ +
        customerOrderNum +
        endpoints.SUBORDER_ITEMS_CREATE,
      dataObject,
      {
        xsrfCookieName: "csrftoken",
        xsrfHeaderName: "X-CSRFTOKEN",
        withCredentials: true,
        headers: {
          Accept: "application/json",
        },
      },
    );
}

async function saveAsOrder(id) {
  const response = await axios({
    method: "post",
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFTOKEN",
    withCredentials: true,
    url:
      endpoints.MITCO_API_URL +
      "/v1" +
      endpoints.SAVE_AS_SUBORDER.replace("{quote_num}", id),
    headers: {
      Accept: "application/json",
    },
  });

  if (response.headers?.location) {
    const redirectUrl = response.headers.location;
    const splittedUrl = redirectUrl.split("/");
    return parseInt(splittedUrl[5]);
  }
  return 0;
}

async function createSalesContactComment(customerId, comment, date) {
  const data = {
    company: "mitco",
    customer_num: customerId,
    text: comment,
    date,
  };

  const stringified = Object.keys(data)
    .map(function (k) {
      return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
    })
    .join("&");

  const response = await axios({
    method: "post",
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFTOKEN",
    withCredentials: true,
    url:
      endpoints.MITCO_API_URL +
      "/v1/service_comments/create_sales_contact_comment/?" +
      stringified,
    headers: {
      Accept: "application/json",
    },
  });
  return response.data;
}

async function sendOrderByEmailFax({ isSubOrder, faxEmailData, id }) {
  await axios({
    method: "post",
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFTOKEN",
    withCredentials: true,
    url: `${endpoints.MITCO_API_URL}/v1/${
      isSubOrder ? "suborder" : "quote"
    }/${id}/send_to_client/?company=mitco`,
    headers: {
      Accept: "application/json",
    },
    data: faxEmailData,
  });
}

async function createUPSPackageQuote(
  fromstate,
  fromzip,
  shipment,
  tostate,
  tozip,
) {
  const shipmentArray = shipment ? Object.values(shipment) : [];

  const packages = shipmentArray
    .map((item) => {
      return {
        package: {
          additionalHandling: +!item.boxed,
          declaredvalue: item.dv,
          weight: item.weight,
        },
      };
    })
    .filter((item) => item.package.weight > 0);

  const data = {
    fromstate: fromstate,
    fromzip: fromzip,
    shipment: [packages],
    tostate: tostate,
    tozip: tozip,
  };

  const formatedData = formatObjectToXML(data, "RateShipment");

  const response = await axios({
    method: "post",
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFTOKEN",
    withCredentials: true,
    url: endpoints.MITCO_API_URL + "/v1/rateups/",
    headers: {
      "Content-Type": "application/xml",
      Accept: "application/xml",
    },
    data: formatedData,
  });

  return convert.xml2json(response.data, { compact: true, spaces: 4 });
}

async function createFedExPackageQuote(
  fromstate,
  fromzip,
  shipment,
  tostate,
  tozip,
) {
  const shipmentArray = shipment ? Object.values(shipment) : [];

  const packages = shipmentArray
    .map((item) => {
      return {
        package: {
          additionalHandling: +!item.boxed,
          declaredvalue: item.dv,
          weight: item.weight,
        },
      };
    })
    .filter((item) => item.package.weight > 0);

  const data = {
    fromstate: fromstate,
    fromzip: fromzip,
    shipment: [packages],
    tostate: tostate,
    tozip: tozip,
  };

  const formatedData = formatObjectToXML(data, "RateShipment");

  const response = await axios({
    method: "post",
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFTOKEN",
    withCredentials: true,
    url: endpoints.MITCO_API_URL + "/v1/ratefedex/",
    headers: {
      "Content-Type": "application/xml",
      Accept: "application/xml",
    },
    data: formatedData,
  });

  return convert.xml2json(response.data, { compact: true, spaces: 4 });
}
