import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setCleaningAll } from "../headerSlice";
import { updateSubOrder } from "../subOrderSlice";
import { defaultOrderItem } from "../../../api/models/ItemsModel";
import { apiDelete } from "../../../api/apiDelete";
import { apiGet } from "../../../api/apiGet";
import formatItems from "../../../helpers/formatItems";
import {
  reIndexAllItems,
  updateItemsProcess,
  hasBackOrders,
  getMessagesOfBackOrdersProcess,
  setProductData,
  calculateBackOrdered,
} from "./item";
import { getCustomerById } from "../customer/customerSlice";

import {
  ORDER_ITEM_MOUNT_TYPE,
  ORDER_ITEM_DESCRIPTION_TYPE,
} from "../../../helpers/const";
import { apiPut } from "../../../api/apiPut";
import { apiPost } from "../../../api/apiPost";
import {
  getItemsWithErrors,
  validateItem,
  validateWarehouse,
} from "../../../helpers/helperFunctions";

/**
 * Thunk function to delete items
 * @param {object} items
 * @param {number} subOrderId
 */
export const deleteItems = createAsyncThunk(
  "items/Delete",
  async (itemsToDelete, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        subOrder: { subOrderId },
        items: { items },
      } = storeStates;

      const deletePromises = itemsToDelete.map((item) => {
        return apiDelete.lineItem(item.id, subOrderId);
      });

      await Promise.all(deletePromises.map((f) => f())).then();

      const arrayItemsDeleted = itemsToDelete.map((i) => i.id);
      const itemsToUpdate = items.filter((i) => {
        if (!arrayItemsDeleted.includes(i.id) && i.id > 0) {
          return true;
        }
        return false;
      });

      const indexedItems = reIndexAllItems(itemsToUpdate);
      await updateItemsProcess(indexedItems, subOrderId);

      const subOrder = await apiGet.subOrderById(subOrderId);
      const newItems = items.filter((item) => item.id === 0);

      const formattedItems = formatItems(
        subOrder.line_items,
        subOrder.status,
        "subOrder",
      );

      const updatedItems = reIndexAllItems([...formattedItems, ...newItems]);
      return updatedItems;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error deleting the item.",
      });
    }
  },
);

/**
 * Thunk function to get products by the ID
 * @param {object} productCode
 */
export const searchProductsById = createAsyncThunk(
  "items/get",
  async ({ productCode, itemLn }, thunkAPI) => {
    try {
      if (productCode) {
        const response = await apiGet.productSearchById(productCode);
        const productsId = await response.map(
          (product) => product.product_code,
        );

        return {
          productsId,
          itemLn,
        };
      }
      return null;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error searching the products.",
      });
    }
  },
);

/**
 * Thunk function to get set item data when an user select a product
 * @param {string} productCode
 * @param {string} itemType
 * @param {number} ln
 */
export const productChangeEvent = createAsyncThunk(
  "items/selectProduct",
  async ({ productCode, itemType, ln }, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        customer: { customerId },
        subOrder: { customer_invoice_num },
        items: { items: allItems },
      } = storeStates;

      const product = await apiGet.productById(productCode);
      const discountSchedule = await apiGet.productInitialPriceById(
        productCode,
        customerId,
        itemType,
        customer_invoice_num,
      );
      const discountSchedules = await apiGet.discountSchedulesForProduct(
        productCode,
      );
      return setProductData({
        product,
        discountSchedule,
        discountSchedules,
        ln,
        allItems,
        productCode,
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error selecting the product.",
      });
    }
  },
);

/**
 * Thunk function to get set item data when an user select a wh
 * @param {string} wh
 * @param {number} ln
 */
export const whChangeEvent = createAsyncThunk(
  "item/whChangeEvent",
  async ({ wh, ln }, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        customer: { customerId },
        subOrder: { customer_invoice_num },
        items: { items: allItems },
      } = storeStates;

      const item = allItems.find((i) => i.ln === ln);

      if (item.productCode) {
        const pricingInfo = await apiGet.productPriceById(
          item.productCode,
          item.d,
          null,
          customerId,
          item.type,
          item.s,
          customer_invoice_num,
          null,
          wh.id,
        );

        const backOrdered = calculateBackOrdered({
          qrdValue: item.qrd,
          availValue: wh.productAvailability,
        });
        const clonedItems = JSON.parse(JSON.stringify(allItems));
        const finalItems = clonedItems.map((item) => {
          if (item.ln === ln) {
            return {
              ...item,
              avail: wh.productAvailability,
              weight: item.unitWeight * (item.qrd - backOrdered),
              ext: pricingInfo.price * (item.qrd - backOrdered),
              wsurch: pricingInfo.price * (item.qrd - backOrdered),
              qrd: item.qrd,
              bo: backOrdered,
              wh: { id: wh.id, name: wh.name },
            };
          }
          return item;
        });
        return {
          items: finalItems,
          warehouse: {
            warehouse_id: wh.id,
            warehouse_name: wh.name,
          },
        };
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error selecting the warehouse.",
      });
    }
  },
);

/**
 * Thunk function to get discountSchedule info
 * @param {string} s
 * @param {number} ln
 */
export const getProductPriceById = createAsyncThunk(
  "item/getProductPriceById",
  async ({ s, d, price, ln }, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        customer: { customerId },
        subOrder: { customer_invoice_num },
        items: { items: allItems },
      } = storeStates;

      const item = allItems.find((i) => i.ln === ln);

      if (item.productCode) {
        const discountSchedule = await apiGet.productPriceById(
          item.productCode,
          d,
          price,
          customerId,
          item.itemType,
          s,
          customer_invoice_num,
          null,
          item.wh.id,
        );

        const clonedItems = JSON.parse(JSON.stringify(allItems));
        const finalItems = clonedItems.map((i) => {
          if (i.ln === ln) {
            if (price) {
              i.item_price = price;
            }
            return {
              ...i,
              s,
              d: discountSchedule.discount_schedule,
            };
          }
          return i;
        });
        return finalItems;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting the discoiunt schedules.",
      });
    }
  },
);

export const dChangeEvent = createAsyncThunk(
  "item/dChangeEvent",
  async ({ ln, dValue }, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        customer: { customerId },
        subOrder: { subOrderId },
        items: { items: allItems },
      } = storeStates;

      const item = allItems.find((i) => i.ln === ln);
      const priceOverride = item.hasPriceOverride ? item.item_price : null;

      const price = await apiGet.productPriceById(
        item.productCode,
        dValue,
        priceOverride,
        customerId,
        item.itemType,
        item.s,
        subOrderId,
        null,
        item.wh.id,
      );

      if (price?.price) {
        const clonedItems = JSON.parse(JSON.stringify(allItems));
        const finalItems = clonedItems.map((i) => {
          if (i.ln === ln) {
            return {
              ...i,
              item_price: Number(price.price),
              ext: Number(price.price).toFixed(2) * (i.qrd - i.bo),
              wsurch: Number(price.price).toFixed(2) * (i.qrd - i.bo),
              d: price.discount_schedule,
            };
          }
          return i;
        });
        return finalItems;
      }
      return allItems;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error selecting the D option.",
      });
    }
  },
);

export const priceOverride = createAsyncThunk(
  "item/priceOverride",
  async ({ ln, price }, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        customer: { customerId },
        subOrder: { subOrderId },
        items: { items: allItems },
      } = storeStates;

      const item = allItems.find((i) => i.ln === ln);

      const productPrice = await apiGet.productPriceById(
        item.productCode,
        item.d,
        price,
        customerId,
        item.itemType,
        item.s,
        subOrderId,
        null,
        item.wh.id,
      );

      if (productPrice.price) {
        const clonedItems = JSON.parse(JSON.stringify(allItems));
        const finalItems = clonedItems.map((i) => {
          if (i.ln === ln) {
            return {
              ...i,
              item_price: productPrice.price,
              ext: productPrice.price * (i.qrd - i.bo),
              wsurch: productPrice.price * (i.qrd - i.bo),
              d: productPrice.discount_schedule,
            };
          }
          return i;
        });
        return finalItems;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting the price",
      });
    }
  },
);

export const checkBackordersProcess = createAsyncThunk(
  "items/checkBackordersProcess",
  async ({ productCode, itemType }, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        subOrder: { subOrderId },
      } = storeStates;

      const backorderData = await apiGet.checkBackordersProcess({
        subOrderId,
        productCode,
        itemType,
      });
      let messages = [];

      if (backorderData?.results?.length > 0) {
        messages = getMessagesOfBackOrdersProcess({
          backorderData: backorderData.results,
          productCode,
        });
      }
      return messages;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error deleting the order.",
      });
    }
  },
);

export const customerIdChangeHandler = createAsyncThunk(
  "item/customerIdChange",
  async ({ item }, thunkAPI) => {
    const storeStates = thunkAPI.getState();
    const {
      customer: { customerId },
      subOrder: { customer_invoice_num },
      items: { items: allItems },
    } = storeStates;

    const discountSchedule = await apiGet.productInitialPriceById(
      item.productCode,
      customerId,
      item.itemType,
      customer_invoice_num,
    );

    const clonedItems = JSON.parse(JSON.stringify(allItems));

    const finalItems = clonedItems.map((i) => {
      if (i.ln === item.ln) {
        return {
          ...i,
          item_price: Number(discountSchedule.price),
          s: discountSchedule.price_type,
          d: discountSchedule.discount_schedule,
          ext: discountSchedule.price * (i.qrd - i.bo),
          wsurch: discountSchedule.price * (i.qrd - i.bo),
        };
      }
      return i;
    });
    return finalItems;
  },
);

export const mountOnEvent = createAsyncThunk(
  "item/mountOnEvent",
  async ({ item }, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        customer: { customerId },
        items: { items: allItems },
        subOrder: { subOrderId },
      } = storeStates;

      const productCode = item.productCode;
      const discountSchedule = "Z";
      const priceOverride = 0;
      const itemType = 1;
      const priceType = " ";
      const whId = item.wh.id;

      if (productCode) {
        const price = await apiGet.productPriceById(
          productCode,
          discountSchedule,
          priceOverride,
          customerId,
          itemType,
          priceType,
          subOrderId,
          null,
          whId,
        );

        const updatedOrderItems = [
          ...allItems,
          {
            ...defaultOrderItem,
            isSubItem: true,
            index: item.index,
            itemType: ORDER_ITEM_MOUNT_TYPE,
            ln: item.ln + 1,
            productCode: item.productCode,
            description: "Mounting Charges",
            wh: item.wh,
            d: "Z",
            qrd: item.qrd,
            avail: item.avail,
            item_price: price.price,
            ext: price.price,
            availableProducts: [item.productCode],
          },
          {
            ...defaultOrderItem,
            isSubItem: true,
            index: item.index,
            itemType: ORDER_ITEM_DESCRIPTION_TYPE,
            productCode: item.productCode,
            description: "Mounted On",
            wh: item.wh,
            d: "Z",
            availableProducts: [item.productCode],
          },
        ];

        const indexedItems = reIndexAllItems(
          updatedOrderItems.sort((a, b) => a.index - b.index),
        );

        if (subOrderId && indexedItems.length > 0) {
          const itemsToUpdate = indexedItems.filter((i) => i.id > 0);
          for (const itemToUpdate of itemsToUpdate) {
            await apiPut.lineItem(itemToUpdate, subOrderId);
          }

          const itemsToCreate = indexedItems
            .filter((i) => i.id === 0)
            .map((parentItem) => {
              return apiPost.lineItem(parentItem, subOrderId);
            });

          await Promise.all(itemsToCreate.map((f) => f())).then();

          const subOrder = await apiGet.subOrderById(subOrderId);

          const formattedItems = formatItems(
            subOrder.line_items,
            subOrder.status,
            "subOrder",
          );

          const updatedItems = reIndexAllItems(formattedItems);

          return updatedItems;
        }

        return indexedItems;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error mounting the item.",
      });
    }
  },
);

export const setItem = createAsyncThunk(
  "items/SetItem",
  async ({ attributesToUpdate, ln }, thunkAPI) => {
    try {
      return {
        attributesToUpdate,
        ln,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error setting the item.",
      });
    }
  },
);

export const saveItem = createAsyncThunk(
  "items/SaveItem",
  async (ln, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        subOrder: {
          subOrderId,
          subOrder: { warehouse_id },
        },
        items: { items },
      } = storeStates;

      const lineItem = items.find((item) => item.ln === ln);

      const itemsErrors = items.map((item) => validateItem(item));
      const hasWhError = validateWarehouse(items, warehouse_id);
      const itemsWithErrors = getItemsWithErrors(itemsErrors);

      const curItemHasErrors = !!itemsWithErrors.find((item) => item.ln === ln);

      if (!curItemHasErrors && !hasWhError && subOrderId) {
        if (lineItem.id) {
          await apiPut.lineItem(lineItem, subOrderId);
        } else {
          const createNewItem = apiPost.lineItem(lineItem, subOrderId);
          const createResponse = await Promise.resolve(createNewItem());

          return {
            itemsErrors,
            hasWhError,
            hasItemsErrors: !!itemsWithErrors.length,
            items: items.map((item) => ({
              ...item,
              id: item.ln === ln ? createResponse?.data?.id : item.id,
            })),
          };
        }
      }

      return {
        itemsErrors,
        hasWhError,
        hasItemsErrors: !!itemsWithErrors.length,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error saving the item.",
      });
    }
  },
);

export const getXrefFromProductCode = createAsyncThunk(
  "items/getXrefFromProductCode",
  async ({ customerId, productCode }, thunkAPI) => {
    try {
      const response = await apiGet.xrefProducts({ customerId, productCode });

      return {
        productCode,
        referenceCode: response.count
          ? response.results[0].reference_code
          : null,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting reference code from product code.",
      });
    }
  },
);

export const getAvailableWarehousesForProduct = createAsyncThunk(
  "item/getAvailableWarehousesForProduct",
  async ({ productCode, ln }, thunkAPI) => {
    try {
      const product = await apiGet.productById(productCode);

      return {
        ln,
        warehouses: product?.warehouses,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting warehouses for the product.",
      });
    }
  },
);

export const getDiscountSchedulesForProduct = createAsyncThunk(
  "item/getDiscountSchedulesForProduct",
  async ({ productCode, ln }, thunkAPI) => {
    try {
      const discountSchedules = await apiGet.discountSchedulesForProduct(
        productCode,
      );

      return {
        ln,
        discountSchedules,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting discount schedules for the product.",
      });
    }
  },
);

export const getAppendedSuborderItems = createAsyncThunk(
  "item/getAppendedSuborderItems",
  async ({ subOrderIds, subOrderStatus }, thunkAPI) => {
    try {
      const appendedItems = await Promise.all(
        subOrderIds.map(
          async (subOrderId) => await apiGet.subOrderItems(subOrderId),
        ),
      );

      return formatItems(appendedItems.flat(), subOrderStatus, "appendedOrder");
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting appended subOrders' items.",
      });
    }
  },
);

export const itemSlice = createSlice({
  name: "itemSlice",
  initialState: {
    items: [defaultOrderItem],
    appendedItems: [],
    hasBackOrders: false,
    backOrderProcessStartedMessage: [],
    refCodes: {},
  },
  reducers: {
    setOrderItems: (state, action) => {
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    },
    updateAllItems: (state, action) => {
      const thereAreBo = hasBackOrders(action.payload);
      state.items = action.payload;
      state.hasBackOrders = thereAreBo;
    },
    reIndexItems: (state, action) => {
      const newOrderItems = reIndexAllItems(action.payload);
      const thereAreBo = hasBackOrders(newOrderItems);
      state.items = newOrderItems;
      state.hasBackOrders = thereAreBo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteItems.fulfilled, (state, action) => {
        state.items = action.payload;
      })
      .addCase(setCleaningAll, (state) => {
        state.items = [defaultOrderItem];
      })
      .addCase(updateSubOrder.fulfilled, (state, action) => {
        state.items = action.payload.lineItems;
        state.itemsToDelete = [];
      })
      .addCase(searchProductsById.fulfilled, (state, action) => {
        if (action.payload) {
          state.items = state.items.map((item) => {
            if (item.ln === action.payload.itemLn) {
              return {
                ...item,
                availableProducts: action.payload.productsId,
                loadingProducts: false,
              };
            }
            return item;
          });
        }
      })
      .addCase(productChangeEvent.fulfilled, (state, action) => {
        return {
          ...state,
          items: action.payload,
        };
      })
      .addCase(whChangeEvent.fulfilled, (state, action) => {
        return {
          ...state,
          items: action.payload.items,
        };
      })
      .addCase(getProductPriceById.fulfilled, (state, action) => {
        return {
          ...state,
          items: action.payload,
        };
      })
      .addCase(dChangeEvent.fulfilled, (state, action) => {
        return {
          ...state,
          items: action.payload,
        };
      })
      .addCase(priceOverride.fulfilled, (state, action) => {
        if (action.payload) {
          return {
            ...state,
            items: action.payload,
          };
        }
      })
      .addCase(customerIdChangeHandler.fulfilled, (state, action) => {
        return {
          ...state,
          items: action.payload,
        };
      })
      .addCase(mountOnEvent.fulfilled, (state, action) => {
        if (action.payload) {
          const newOrderItems = action.payload;
          const thereAreBo = hasBackOrders(newOrderItems);
          state.items = newOrderItems;
          state.hasBackOrders = thereAreBo;
        }
      })
      .addCase(getCustomerById.fulfilled, (state) => {
        state.items = [defaultOrderItem];
      })
      .addCase(checkBackordersProcess.fulfilled, (state, action) => {
        state.backOrderProcessStartedMessage = action.payload;
      })
      .addCase(saveItem.fulfilled, (state, action) => {
        if (action.payload.items) {
          state.items = action.payload.items;
        }
      })
      .addCase(setItem.fulfilled, (state, action) => {
        const newItems = state.items.map((item) =>
          item.ln === action.payload.ln
            ? {
                ...item,
                ...action.payload.attributesToUpdate,
              }
            : item,
        );
        const thereAreBo = hasBackOrders(newItems);
        state.items = newItems;
        state.hasBackOrders = thereAreBo;
      })
      .addCase(getXrefFromProductCode.fulfilled, (state, action) => {
        if (action.payload.referenceCode) {
          state.refCodes[action.payload.productCode] =
            action.payload.referenceCode;
        }
      })
      .addCase(getAvailableWarehousesForProduct.fulfilled, (state, action) => {
        state.items = state.items.map((item) =>
          item.ln === action.payload.ln
            ? {
                ...item,
                warehouses: action.payload.warehouses,
                loadingWarehouses: false,
              }
            : item,
        );
      })
      .addCase(getDiscountSchedulesForProduct.fulfilled, (state, action) => {
        state.items = state.items.map((item) =>
          item.ln === action.payload.ln
            ? {
                ...item,
                availableDiscounts: action.payload.discountSchedules,
                loadingDiscountSchedules: false,
              }
            : item,
        );
      })
      .addCase(getAppendedSuborderItems.fulfilled, (state, action) => {
        state.appendedItems = action.payload;
      });
  },
});

export const { setOrderItems, updateAllItems, reIndexItems } =
  itemSlice.actions;
export default itemSlice.reducer;
