import { memo } from "react";
import {
  TextField,
  Autocomplete,
  IconButton,
  ButtonGroup,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { formStyle } from "../../../theming/formStyle";
import { TbSwitch3, TbTruckDelivery } from "react-icons/tb";
import { AiFillDelete } from "react-icons/ai";
import ToolTip from "../ToolTip";
import ErrorIcon from "../ErrorIcon/ErrorIcon";
import ConfirmationAlertBySteps from "../ConfirmationAlertBySteps";
import useItem from "../../../hooks/useItem";
import useItemButtons from "../../../hooks/useItemButtons";
import "./InventoryOrderItem.scss";
import InputNumber from "../InputNumber";

function InventoryOrderItem({ item, allowedActions }) {
  // hooks
  const {
    onKeyDownHandler,
    setProductCode,
    setPrice,
    setDError,
    onClickHandler,
    handleWhChange,
    handleSChange,
    handleDChange,
    handleTqChange,
    handleQrdChange,
    handleBoChange,
    handlePriceChange,
    handleProductChange,
    checkPriceOverride,
    getAvailableWarehouses,
    getDiscountSchedules,
    price,
    refCode,
    productError,
    dError,
    tqError,
    qrdError,
    boError,
    itemPriceError,
    productRef,
    whInputRef,
    sInputRef,
    dInputRef,
    tqInputRef,
    qrdInputRef,
    boInputRef,
    priceInputRef,
  } = useItem({ item });

  const {
    deletingProcess,
    deleteItem,
    setShowDeleteItemPopup,
    deletionMessages,
    showDeleteItemPopup,
    mountOn,
  } = useItemButtons(item);

  return (
    <>
      <tr className="orderItem-row">
        <td className="align-middle">{item.ln}</td>
        <td>
          <div className="d-flex">
            {refCode && (
              <ToolTip title={refCode}>
                <span>
                  <InfoOutlinedIcon className="mt-4" />
                </span>
              </ToolTip>
            )}
            <Autocomplete
              disableClearable
              className="autocomplete-input w-100"
              options={item.availableProducts}
              name="productCode"
              disabled={item.isLocked}
              value={item.productCode}
              getOptionLabel={(option) => {
                return option.toString();
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  error={productError.error}
                  inputRef={productRef}
                  onKeyDown={(event) => onKeyDownHandler(event, whInputRef)}
                  onChange={(_e) => {
                    setProductCode(_e.target.value);
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {item.loadingProducts ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  onClick={onClickHandler}
                />
              )}
              openOnFocus
              onChange={(_e, value) => handleProductChange(value)}
            />
            {productError.error && (
              <ToolTip
                title={productError.msg}
                textColor="#fff"
                backgroundColor="#d32f2f"
              >
                <span>
                  <ErrorIcon />
                </span>
              </ToolTip>
            )}
          </div>
        </td>
        <td className="align-middle">{item.description}</td>
        <td>
          <Autocomplete
            disableClearable
            className="autocomplete-input"
            disabled={item.isLocked}
            options={item.warehouses.map((warehouse) => {
              return {
                label: warehouse.warehouse_name + ":" + warehouse.qty_available,
                name: warehouse.warehouse_name,
                id: warehouse.warehouse_id,
                productAvailability: warehouse.qty_available,
              };
            })}
            onFocus={() => {
              if (!item.warehouses?.length) {
                getAvailableWarehouses();
              }
            }}
            name="wh"
            style={{ width: 66 }}
            value={item.wh.name}
            openOnFocus
            onChange={(e, value) => handleWhChange(value)}
            noOptionsText={
              item.loadingWarehouses ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                "No options"
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                style={formStyle.inputStyle}
                InputLabelProps={formStyle.inputLabelProps}
                inputRef={whInputRef}
                onKeyDown={(event) => onKeyDownHandler(event, sInputRef)}
                onClick={onClickHandler}
              />
            )}
          />
        </td>
        <td>
          <Autocomplete
            disableClearable
            className="autocomplete-input"
            disabled={item.isLocked}
            value={item.s}
            name="s"
            style={{ width: 60 }}
            onChange={(e, value) => handleSChange(value)}
            options={[
              {
                label: "",
                name: "",
              },
              {
                label: "D",
                name: "D",
              },
              {
                label: "S",
                name: "S",
              },
            ]}
            onFocus={() => {
              if (!item.availableDiscounts?.length) {
                getDiscountSchedules();
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="s"
                style={formStyle.inputStyle}
                InputLabelProps={formStyle.inputLabelProps}
                inputRef={sInputRef}
                onKeyDown={(event) => onKeyDownHandler(event, dInputRef)}
                onClick={onClickHandler}
              />
            )}
          />
        </td>
        <td>
          <Autocomplete
            disableClearable
            disabled={item.isLocked || item.s !== "D"}
            className="autocomplete-input"
            options={item?.availableDiscounts}
            name="d"
            style={{ width: 60 }}
            value={item.d}
            onFocus={() => {
              if (!item.availableDiscounts?.length) {
                getDiscountSchedules();
              }
            }}
            onChange={(_e, value) => handleDChange(value)}
            noOptionsText={
              item.loadingDiscountSchedules ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                "No options"
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                style={formStyle.inputStyle}
                InputLabelProps={formStyle.inputLabelProps}
                inputRef={dInputRef}
                error={dError.error}
                onKeyDown={(event) => onKeyDownHandler(event, tqInputRef)}
                onClick={onClickHandler}
                onBlur={(e) => {
                  setDError({ error: false, msg: "" });
                }}
                onChange={(e) => {
                  if (
                    !item?.availableDiscounts.includes(
                      e.currentTarget.value.toUpperCase(),
                    )
                  ) {
                    setDError({
                      error: true,
                      msg: "Enter a valid value that is in the discount list",
                    });
                  } else {
                    setDError({ error: false, msg: "" });
                  }
                }}
              />
            )}
            openOnFocus
          />
          {dError.error && (
            <ToolTip
              title={dError.msg}
              textColor="#fff"
              backgroundColor="#d32f2f"
            >
              <span>
                <ErrorIcon />
              </span>
            </ToolTip>
          )}
        </td>
        <td>
          <InputNumber
            id="filled-basic"
            name="tq"
            disabled={item.isLocked}
            value={item.tq}
            onChange={(e) => handleTqChange(parseInt(e.target.value))}
            error={tqError.error}
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            inputRef={tqInputRef}
            onKeyDown={(event) => onKeyDownHandler(event, qrdInputRef, "tq")}
            onClick={onClickHandler}
          />
          {tqError.error && (
            <ToolTip
              title={tqError.msg}
              textColor="#fff"
              backgroundColor="#d32f2f"
            >
              <span>
                <ErrorIcon />
              </span>
            </ToolTip>
          )}
        </td>
        <td>
          <InputNumber
            id="filled-basic"
            name="qrd"
            disabled={item.isLocked}
            value={item.qrd}
            onChange={(e) => handleQrdChange(parseInt(e.target.value))}
            error={qrdError.error}
            InputProps={{
              inputProps: { min: 0 },
            }}
            inputRef={qrdInputRef}
            onKeyDown={(event) => onKeyDownHandler(event, boInputRef, "qrd")}
            onClick={onClickHandler}
          />
          {qrdError.error && (
            <ToolTip
              title={qrdError.msg}
              textColor="#fff"
              backgroundColor="#d32f2f"
            >
              <span>
                <ErrorIcon />
              </span>
            </ToolTip>
          )}
        </td>
        <td>
          <InputNumber
            id="filled-basic"
            disabled={item.isLocked}
            value={item.bo}
            name="bo"
            onChange={(e) => handleBoChange(parseInt(e.target.value))}
            error={boError.error}
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            inputRef={boInputRef}
            onKeyDown={(event) => onKeyDownHandler(event, priceInputRef, "bo")}
            onClick={onClickHandler}
          />
          {boError.error && (
            <ToolTip
              title={boError.msg}
              textColor="#fff"
              backgroundColor="#d32f2f"
            >
              <span>
                <ErrorIcon />
              </span>
            </ToolTip>
          )}
        </td>
        <td>
          <InputNumber
            id="filled-basic"
            name="avail"
            className="input-read-only"
            disabled={true}
            value={item.avail}
          />
        </td>
        <td>
          <InputNumber
            id="filled-basic"
            name="item_price"
            disabled={item.s === "D" || item.isLocked ? true : false}
            className={item.s !== "S" ? "input-read-only" : ""}
            InputProps={
              item.s !== "S"
                ? {
                    readOnly: true,
                  }
                : {}
            }
            // value={item?.item_price ? item.item_price.toFixed(2) : item.item_price}
            value={price}
            onBlur={(e) => {
              checkPriceOverride(parseFloat(e.target.value));
              handlePriceChange(parseFloat(e.target.value));
            }}
            error={itemPriceError.error}
            //onChange={(e) => handlePriceChange(e.target.value)}
            onChange={(e) => setPrice(e.target.value)}
            inputRef={priceInputRef}
            onKeyDown={(event) => onKeyDownHandler(event)}
            onClick={onClickHandler}
          />
          {itemPriceError.error && (
            <ToolTip
              title={itemPriceError.msg}
              textColor="#fff"
              backgroundColor="#d32f2f"
            >
              <span>
                <ErrorIcon />
              </span>
            </ToolTip>
          )}
        </td>
        <td>
          <InputNumber
            id="filled-basic"
            name="ext"
            disabled={true}
            className="input-read-only"
            value={item.ext.toFixed(2)}
            InputProps={{ readOnly: true }}
            style={{ width: 84 }}
          />
        </td>
        <td>
          <InputNumber
            id="filled-basic"
            name="weight"
            disabled={true}
            value={item.weight}
            className="input-read-only"
            InputProps={{ readOnly: true }}
          />
        </td>
        <td>
          <InputNumber
            id="filled-basic"
            name="wsurch"
            disabled={true}
            className="input-read-only"
            value={item.wsurch.toFixed(2)}
            InputProps={{ readOnly: true }}
            style={{ width: 84 }}
          />
        </td>
        <td>
          <ButtonGroup aria-label="action-button-group" className="mt-4">
            <Tooltip title="Switch" arrow placement="top">
              <IconButton
                color="primary"
                disabled={!allowedActions.switch}
                size="small"
              >
                <TbSwitch3 />
              </IconButton>
            </Tooltip>
            <Tooltip title="Mount" arrow placement="top">
              <IconButton
                color="primary"
                onClick={(_e) => mountOn(item)}
                disabled={!allowedActions.mt || item.isLocked}
                size="small"
              >
                <TbTruckDelivery />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete" arrow placement="top">
              <IconButton
                color="primary"
                onClick={deletingProcess}
                disabled={!allowedActions.delete || item.isLocked}
                size="small"
              >
                <AiFillDelete />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        </td>
      </tr>
      <ConfirmationAlertBySteps
        title="Process of deletion"
        show={showDeleteItemPopup}
        close={() => setShowDeleteItemPopup(false)}
        steps={deletionMessages}
        confirmationLabel="Delete"
        handledCancelEvent={() => setShowDeleteItemPopup(false)}
        handleConfirmEvent={() => deleteItem(item)}
      />
    </>
  );
}

export default memo(InventoryOrderItem, (prevProps, nextProps) => {
  if (
    prevProps.item.id === nextProps.item.id &&
    prevProps.item.ln === nextProps.item.ln &&
    prevProps.item.itemType === nextProps.item.itemType &&
    prevProps.item.isLocked === nextProps.item.isLocked &&
    prevProps.item.productCode === nextProps.item.productCode &&
    prevProps.item.availableProducts === nextProps.item.availableProducts &&
    prevProps.item.warehouses === nextProps.item.warehouses &&
    prevProps.item.wh.id === nextProps.item.wh.id &&
    prevProps.item.s === nextProps.item.s &&
    prevProps.item.availableDiscounts === nextProps.item.availableDiscounts &&
    prevProps.item.d === nextProps.item.d &&
    prevProps.item.tq === nextProps.item.tq &&
    prevProps.item.qrd === nextProps.item.qrd &&
    prevProps.item.bo === nextProps.item.bo &&
    prevProps.item.avail === nextProps.item.avail &&
    prevProps.item.ext === nextProps.item.ext &&
    prevProps.item.weight === nextProps.item.weight &&
    prevProps.item.wsurch === nextProps.item.wsurch &&
    prevProps.item.wsurch === nextProps.item.wsurch &&
    prevProps.item.item_price === nextProps.item.item_price &&
    prevProps.item.errors.product === nextProps.item.errors.product &&
    prevProps.item.errors.s === nextProps.item.errors.s &&
    prevProps.item.errors.d === nextProps.item.errors.d &&
    prevProps.item.errors.tq === nextProps.item.errors.tq &&
    prevProps.item.errors.qrd === nextProps.item.errors.qrd &&
    prevProps.item.errors.bo === nextProps.item.errors.bo &&
    prevProps.item.errors.itemPrice === nextProps.item.errors.itemPrice &&
    prevProps.item.loadingProducts === nextProps.item.loadingProducts &&
    prevProps.item.loadingWarehouses === nextProps.item.loadingWarehouses &&
    prevProps.item.loadingDiscountSchedules ===
      nextProps.item.loadingDiscountSchedules &&
    prevProps.allowedActions.mt === nextProps.allowedActions.mt &&
    prevProps.allowedActions.delete === nextProps.allowedActions.delete
  ) {
    return true;
  }
  return false;
});
