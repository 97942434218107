import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSubOrder, resetSubOrderId, updateSubOrder } from "../subOrderSlice";
import {
  setCustomerId,
  getCustomerById,
  searchCustomersById,
} from "../customer/customerSlice";
import { getQuote } from "../quoteSlice";
import { apiGet } from "../../../api/apiGet";
import { setCustomerData } from "../customer/customerSlice";
import { setCleaningAll } from "../headerSlice";
import { refillFreightData, verifyFreightLine } from "./freight";
import { PREPAID_WEIGHT } from "../../../helpers/const";

import { apiPost } from "../../../api/apiPost";

import { INTIAL_PACKAGE_RATER_HANLDING } from "../../../helpers/const";
import { extractIdFromRefUrl } from "../../../helpers/helperFunctions";

/**
 *  Get drop ship addresses
 *  @param {number} page
 *  @param {object} thunkAPI - Redux object
 */
export const getDropShipAddresses = createAsyncThunk(
  "dropShip/all",
  async ({ page }, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        customer: { customerId },
        freight: {
          dropShipAddress: { id, name, city, route, state, street, zipCode },
        },
      } = storeStates;
      let addresses = [];

      if (id) {
        addresses = await apiGet.dropShipAddresses(
          customerId,
          "mitco",
          null,
          null,
          null,
          null,
          null,
          null,
          page,
        );
      } else {
        addresses = await apiGet.dropShipAddresses(
          customerId,
          "mitco",
          name,
          city,
          route,
          state,
          street,
          zipCode,
          page,
        );
      }

      return addresses;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting the drop ship addresses.",
      });
    }
  },
);

/**
 *  Get freight rater options
 *  @param {number} page
 *  @param {object} thunkAPI - Redux object
 */
export const getRateLTLOptions = createAsyncThunk(
  "freightRater/rateLTL",
  async (_, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        freight: {
          weight,
          bolDetail: { appended_weight = 0 },
          freightRater: {
            czarZip,
            destinationZip,
            testCzarZip,
            testDestinationZip,
          },
        },
      } = storeStates;

      const fromZip = testCzarZip || czarZip;
      const toZip = testDestinationZip || destinationZip;

      const freightClass = "50"; // According to Eric Vaughn this is always 50
      const response = await apiGet.rateLTLOptions(
        fromZip,
        toZip,
        weight + appended_weight,
        freightClass,
      );

      return response;
    } catch (err) {
      if (err?.response?.data?.detail) {
        return thunkAPI.rejectWithValue({
          axiosError: err,
          customMsg: err.response.data.detail,
        });
      }
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error loading freight rater options.",
      });
    }
  },
);

/**
 *  Get package rater options
 *  @param {number} page
 *  @param {object} thunkAPI - Redux object
 */
export const getPackageRaterOptions = createAsyncThunk(
  "freightRater/packageRater",
  async (_, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        freight: {
          freightRater: {
            czarZip,
            destinationZip,
            packages,
            packageRaterHandling,
            testCzarZip,
            testDestinationZip,
          },
        },
      } = storeStates;

      const fromZip = testCzarZip || czarZip;
      const toZip = testDestinationZip || destinationZip;

      const fromState = await apiGet.getStateFromZipCode(fromZip);
      const toState = await apiGet.getStateFromZipCode(toZip);

      if (!fromState) {
        return thunkAPI.rejectWithValue({
          customMsg: "Invalid Czar zip code.",
        });
      }

      if (!toState) {
        return thunkAPI.rejectWithValue({
          customMsg:
            "Unable to use package rater with this destination zip code.",
        });
      }

      if (!Object.values(packages).find((item) => +item.weight)) {
        return thunkAPI.rejectWithValue({
          customMsg: "At least one package should have a weight.",
        });
      }

      let upsServices = [];
      let fedexServices = [];
      let upsStatus = true;
      let fedexStatus = true;

      try {
        const resultUPS = await apiPost.createUPSPackageQuote(
          fromState,
          fromZip,
          packages,
          toState,
          toZip,
          packageRaterHandling,
        );

        const resultUPSJson = JSON.parse(resultUPS)?.RatedServices?.Service;

        // upsService might be array or object. So we should check out it is array to use 'map' function.
        upsServices = Array.isArray(resultUPSJson)
          ? resultUPSJson
          : [resultUPSJson];
      } catch (err) {
        upsStatus = false;
        console.log(err);
      }

      try {
        const resultFedEx = await apiPost.createFedExPackageQuote(
          fromState,
          fromZip,
          packages,
          toState,
          toZip,
          packageRaterHandling,
        );

        const resultFedExJson = JSON.parse(resultFedEx)?.RatedServices?.Service;

        // fedexService might be array or object. So we should check out it is array to use 'map' function.
        fedexServices = Array.isArray(resultFedExJson)
          ? resultFedExJson
          : [resultFedExJson];
      } catch (err) {
        fedexStatus = false;
        console.log(err);
      }

      const formattedUPSOptions = upsServices.map((option, index) => ({
        id: index + 1,
        postalService: "UPS",
        service: option.Description._text,
        rate: option.TotalCharges._text,
        days: option.BusinessDaysInTransit._text,
        goalDeliveryTarget: option.ScheduledDeliveryTime._text,
      }));

      const formattedFedexOptions = fedexServices.map((option, index) => ({
        id: formattedUPSOptions.length + index + 1,
        postalService: "FedEx",
        service: option.Description._text,
        rate: option.TotalCharges._text,
        days: option.BusinessDaysInTransit._text,
        goalDeliveryTarget: option.ScheduledDeliveryTime._text,
      }));

      const packageRaterAllOptions = [
        ...formattedUPSOptions,
        ...formattedFedexOptions,
      ];

      packageRaterAllOptions.sort(
        (a, b) => parseFloat(a.rate) - parseFloat(b.rate),
      );

      return { packageRaterAllOptions, upsStatus, fedexStatus };
    } catch (err) {
      if (err?.response?.data?.detail) {
        return thunkAPI.rejectWithValue({
          axiosError: err,
          customMsg: err.response.data.detail,
        });
      }
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error loading package rater options.",
      });
    }
  },
);

/**
 * Get warehouse zipcode and name for czarZIp
 */
/// UNTESTED
export const getWarehouseInformation = createAsyncThunk(
  "freightRater/getWareHouseInformation",
  async (warehouse_id, thunkAPI) => {
    try {
      const response = await apiGet.warehouseById(warehouse_id);

      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting warehouse information.",
      });
    }
  },
);

/**
 * Verifying Prepaid Freight Details
 * @param {number} amount - amount entered in the prepaid freight field
 * @param {object} thunkAPI - Redux object
 */
export const verifyFreightDetails = createAsyncThunk(
  "freight/verifyFreightDetails",
  async (_, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        customer: { customerId },
        freight: { freightAmount, weight },
      } = storeStates;
      const freightDetails = await apiGet.verifyFreightDetails({
        customerNum: customerId,
        amount: freightAmount,
        weight,
      });
      let freightLines = null;
      const freightLineId = verifyFreightLine(freightDetails);

      if (freightLineId) {
        freightLines = await apiGet.freightLineList({ id: freightLineId });
      }

      return {
        freightLine: freightLines?.data?.results
          ? freightLines.data.results[0]
          : null,
        freightDetails,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error verifying freight detail.",
      });
    }
  },
);

/**
 * Apply Customer Shipping Rules
 * @param {object} thunkAPI - Redux object
 */
export const applyShippingRules = createAsyncThunk(
  "freight/applyShippingRules",
  async (_, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        customer: { customerId },
        freight: {
          weight,
          prepaidCode,
          freightLine: { id: freightLineId },
        },
      } = storeStates;
      const response = await apiGet.applyShippingRules({
        customerNum: customerId,
        prepaidCode,
        freightLineId,
        weight,
      });

      if (response.length) {
        const parsedResponse = response.reduce(
          (prev, cur) => ({
            ...prev,
            [cur.field]: cur.value,
          }),
          {},
        );
        const parsedPrepaid = refillFreightData({ data: response });

        let freightLines;
        if (parsedResponse["freight.freight_line_id"]) {
          freightLines = await apiGet.freightLineList({
            id: parsedResponse["freight.freight_line_id"],
          });
        }

        return {
          prepaidCode: parsedPrepaid.prepaidCode,
          prepaid: parsedPrepaid.prepaid,
          freightLine: freightLines?.data?.results
            ? freightLines.data.results[0]
            : undefined,
          thirdPartyBilling: {
            name: parsedResponse["third_party_billing.name"],
            addr1: parsedResponse["third_party_billing.addr1"],
            addr2: parsedResponse["third_party_billing.addr2"],
            city: parsedResponse["third_party_billing.city"],
            state: parsedResponse["third_party_billing.state"],
            account: parsedResponse["third_party_billing.account"],
            zipCode: parsedResponse["third_party_billing.zip_code"],
          },
          confirmText: {
            text1: parsedResponse["ux.confirm_text1"],
            text2: parsedResponse["ux.confirm_text2"],
          },
          status: "Success",
        };
      } else {
        return {
          status: "No Results",
        };
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error applying shipping rules.",
      });
    }
  },
);

/**
 * Get the list of lines
 */
export const getFreightLineList = createAsyncThunk(
  "freight/freightLineList",
  async ({ page, name, id }, thunkAPI) => {
    try {
      const data = await apiGet.freightLineList({ page, name, id });
      if (data?.data?.results) {
        return data.data.results;
      }
      return [];
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting freight lines list.",
      });
    }
  },
);

export const getFreightLineById = createAsyncThunk(
  "freight/freightById",
  async (id, thunkAPI) => {
    try {
      const res = await apiGet.getFreightLineById(id);

      return res;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting freight line.",
      });
    }
  },
);

/**
 * Get freight lines filtered by scac_code
 * @param {number} freighRaterId
 */
export const getFreightLinesByScacCode = createAsyncThunk(
  "freight/freightLineByScacCode",
  async ({ carrier }, thunkAPI) => {
    try {
      if (carrier) {
        const freightLines = await apiGet.freightLineList({
          scacCode: carrier,
        });
        if (freightLines?.data?.results) {
          if (freightLines?.data?.results.length > 0) {
            return freightLines.data.results[0];
          }
        }
      }
      return null;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error loading freight rater options.",
      });
    }
  },
);

export const getBolAppendable = createAsyncThunk(
  "freight/bolAppendable",
  async ({ subOrderId, billOfLading, warehouse_id }, thunkAPI) => {
    try {
      if (subOrderId > 0 && subOrderId === billOfLading && warehouse_id >= 0) {
        const response = await apiGet.getBolList({
          bill_of_lading: subOrderId,
          warehouse_id,
          appendable: true,
        });

        return !!response.count;
      } else {
        return false;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({
        axiosError: error,
        customMsg: "Error getting BOL appendable.",
      });
    }
  },
);

export const getBolDetail = createAsyncThunk(
  "freight/bolDetail",
  async (subOrderId, thunkAPI) => {
    try {
      const response = await apiGet.getBolById(subOrderId);

      return {
        ...response,
        subOrderIds: response.suborders
          .map((item) => extractIdFromRefUrl(item))
          .filter((item) => item !== subOrderId),
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({
        axiosError: error,
        customMsg: "Error getting Bill Of Lading.",
      });
    }
  },
);

export const getAppendedSubOrdersInfo = createAsyncThunk(
  "freight/appenedSubOrdersInfo",
  async ({ subOrderIds }, thunkAPI) => {
    try {
      const appendedSubOrders = await Promise.all(
        subOrderIds.map(async (suborderID) => {
          const subOrder = await apiGet.subOrderById(suborderID);

          return subOrder;
        }),
      );

      return appendedSubOrders.map((item) => ({
        id: item.customer_order_num,
        totalWeight: item.freight?.weight,
        itemsCount: item.line_items?.length,
      }));
    } catch (error) {
      return thunkAPI.rejectWithValue({
        axiosError: error,
        customMsg: "Error getting the appended orders for BoL.",
      });
    }
  },
);

const initialDropShipAddressSetup = {
  id: 0,
  name: "",
  open: false,
  city: "",
  route: "",
  state: "",
  street: "",
  zipCode: "",
  email: "",
  fax: "",
  contactName: "",
  phone: "",
  addresses: [],
  total: 0,
  isDropShip: false,
};

const initialFreightRaterSetup = {
  open: false,
  warehouseNumber: "",
  longName: "",
  warehouseState: "",
  czarZip: "",
  isDropShip: false,
  destinationZip: "",
  destinationState: "",
  carrierQuoteNumber: "",
  TMSQuoteNumber: "",
  rated: false,
  ratedWeight: 0,
  packages: [],
  packageRaterOptions: [],
  rateLTLOptions: [],
  selectedRateLTLOption: null,
  selectedPackageRaterOption: null,
  packageRaterHandling: INTIAL_PACKAGE_RATER_HANLDING,
  testCzarZip: "",
  testDestinationZip: "",
  testCzarState: "",
  testDestinationState: "",
  upsStatus: true,
  fedexStatus: true,
};

const initialState = {
  freightRater: initialFreightRaterSetup,
  dropShipAddress: initialDropShipAddressSetup,
  billOfLading: 0,
  billAppendable: false,
  bolDetail: {},
  appendedSubOrders: {
    loading: false,
    data: [],
  },
  freightAmount: 0,
  estimatedCost: 0,
  weight: 0,
  isDirectShip: false,
  deliveryTarget: 0,
  freightLine: {
    id: 149,
    title: "149 - FTS TBD",
  },
  handlingCharge: INTIAL_PACKAGE_RATER_HANLDING,
  prepaid: false,
  blindShip: false,
  triggerDropShipFocus: false,
  availableFreightLines: [],
  prepaidCode: "N",
  thirdPartyBilling: {
    name: "",
    addr1: "",
    addr2: "",
    city: "",
    state: "",
    account: "",
    zipCode: "",
  },
  actualCost: 0,
  prepaidWeight: 0,
  loadingFreightDetails: false,
};

export const freightSlice = createSlice({
  name: "freightSlice",
  initialState,
  reducers: {
    setFreight: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setFreightLine: (state, action) => {
      return {
        ...state,
        freightLine: action.payload,
        freightRater: {
          ...state.freightRater,
          TMSQuoteNumber: "",
          carrierQuoteNumber: "",
          rated: false,
        },
        isUpdated: true,
      };
    },
    setFreightPackages: (state, action) => {
      return {
        ...state,
        freightRater: {
          ...state.freightRater,
          packages: action.payload,
        },
      };
    },
    setSelectedRateLTLOption: (state, action) => {
      return {
        ...state,
        freightRater: {
          ...state.freightRater,
          selectedRateLTLOption: action.payload,
        },
      };
    },
    setSelectedPackageRaterOption: (state, action) => {
      return {
        ...state,
        freightRater: {
          ...state.freightRater,
          selectedPackageRaterOption: action.payload,
        },
      };
    },
    setPrepaid: (state, action) => {
      let prepaidCode = "";
      if (state.thirdPartyBilling.name) {
        prepaidCode = "3";
      } else {
        if (action.payload) {
          prepaidCode = "Y";
        } else {
          prepaidCode = "N";
        }
      }
      state.prepaidCode = prepaidCode;
      state.prepaid = action.payload;
      state.isUpdated = true;
    },
    setThirdPartyBilling: (state, action) => {
      state.thirdPartyBilling = action.payload;
      state.prepaidCode = "3";

      if (!action.payload.name) {
        if (state.prepaid) {
          state.prepaidCode = "Y";
        } else {
          state.prepaidCode = "N";
        }
      }
    },
    setDropShipInformation: (state, action) => {
      if (action.payload?.zipCode) {
        console.log("setDropShipInformation", action.payload);
        return {
          ...state,
          freightRater: {
            ...state.freightRater,
            destinationZip: action.payload.zipCode,
          },
          dropShipAddress: {
            ...state.dropShipAddress,
            ...action.payload,
          },
        };
      }
      if (action.payload?.state) {
        return {
          ...state,
          freightRater: {
            ...state.freightRater,
            destinationState: action.payload.state,
          },
          dropShipAddress: {
            ...state.dropShipAddress,
            ...action.payload,
          },
        };
      }
      return {
        ...state,
        dropShipAddress: {
          ...state.dropShipAddress,
          ...action.payload,
        },
      };
    },
    deleteDropShip: (state, action) => {
      state.dropShipAddress = initialDropShipAddressSetup;
      state.blindShip = false;
      state.freightRater = {
        ...state.freightRater,
        destinationZip: action.payload?.shippingZipCode
          ? action.payload.shippingZipCode
          : "",
        destinationState: action.payload?.shippingState
          ? action.payload.shippingState
          : "",
      };
    },
    toggleDropShipPopupState: (state, action) => {
      state.dropShipAddress.open = action.payload;
      if (action.payload === true) {
        state.triggerDropShipFocus = false;
      } else {
        state.triggerDropShipFocus = true;
      }
    },
    setSelectedDropShip: (state, action) => {
      const selectedAddress = state.dropShipAddress.addresses.find(
        (dsa) => dsa.id === action.payload.addressId,
      );
      if (selectedAddress) {
        state.dropShipAddress = {
          ...state.dropShipAddress,
          ...selectedAddress,
          isDropShip: true,
        };
        state.freightRater = {
          ...state.freightRater,
          destinationZip: selectedAddress.zipCode,
          destinationState: selectedAddress.state,
        };
      } else {
        state.dropShipAddress = {
          ...initialDropShipAddressSetup,
          open: state.dropShipAddress.open,
          addresses: state.dropShipAddress.addresses,
          total: state.dropShipAddress.total,
          isDropShip: false,
        };
        state.blindShip = false;
        state.freightRater = {
          ...state.freightRater,
          // NEED TO ADD CUSTOMER ZIP CODE
          destinationZip: action.payload?.shippingZipCode
            ? action.payload.shippingZipCode
            : "",
          destinationState: action.payload?.shippingState
            ? action.payload.shippingState
            : "",
        };
      }
    },
    toggleFreightRaterPopupState: (state, action) => {
      state.freightRater.open = action.payload;
    },
    deleteFreightRater: (state) => {
      state.freightRater = initialFreightRaterSetup;
    },
    setFreightRater: (state, action) => {
      return {
        ...state,
        freightRater: {
          ...state.freightRater,
          ...action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDropShipAddresses.fulfilled, (state, action) => {
        state.dropShipAddress.addresses = action.payload.addresses;
        state.dropShipAddress.total = action.payload.total;
      })
      .addCase(getSubOrder.pending, (state) => ({ ...initialState }))
      .addCase(getSubOrder.fulfilled, (state, action) => {
        if (action.payload?.subOrderData?.customer) {
          // console.log('getSuborder.fullfilled', action.payload)
          const { zip_code } =
            action.payload.subOrderData.customer.shipping_address;

          // const {
          //     dropship_zipcode: zipCode,
          //     dropship_state: state
          // } = action.payload.freight.dropShipAddress

          const { prepaid_weight } = action.payload.subOrderData.customer;

          state.freightRater = {
            ...state.freightRater,
            destinationZip: zip_code,
            destinationState:
              action.payload.subOrderData.customer.shipping_address.state,
          };

          if (prepaid_weight === 0) state.prepaidWeight = PREPAID_WEIGHT;
          if (Number(prepaid_weight) > 0) state.prepaidWeight = prepaid_weight;
        }
        if (action.payload?.subOrderData?.freight) {
          const {
            bill_of_lading,
            freight_line,
            freight_amount,
            handling_charge,
            is_direct_ship,
            prepaid,
            is_drop_ship,
            drop_ship_address_id,
            estimated_cost,
            actual_cost,
            blind_ship,
            freightline_quote_num,
            tms_quote_num,
            weight,
          } = action.payload.subOrderData.freight;

          const rated = freightline_quote_num || tms_quote_num ? true : false;

          state.billOfLading = bill_of_lading;
          state.freightLine = freight_line;
          state.freightAmount = freight_amount;
          state.handlingCharge = handling_charge;
          state.isDirectShip = is_direct_ship;
          state.estimatedCost = estimated_cost;
          state.prepaid = prepaid;
          state.actualCost = actual_cost;
          state.blindShip = blind_ship;
          state.weight = weight;
          state.freightRater = {
            ...state.freightRater,
            carrierQuoteNumber: freightline_quote_num,
            TMSQuoteNumber: tms_quote_num,
            rated,
            ratedWeight: rated ? weight : 0,
          };

          if (is_drop_ship) {
            const {
              drop_ship_address: {
                ship_to_name,
                address: {
                  street: dSAStreet,
                  route: dSARoute,
                  city: dSACity,
                  state: dSAState,
                  zip_code: dSAZipCode,
                },
                contact: {
                  email: dSAEmail,
                  fax: dSAFax,
                  name: dSAName,
                  phone: dSAPhone,
                },
              },
            } = action.payload.subOrderData.freight;

            state.dropShipAddress = {
              ...state.dropShipAddress,
              id: drop_ship_address_id,
              name: ship_to_name,
              street: dSAStreet,
              route: dSARoute,
              city: dSACity,
              state: dSAState,
              zipCode: dSAZipCode,
              email: dSAEmail,
              fax: dSAFax,
              contactName: dSAName,
              phone: dSAPhone,
              isDropShip: is_drop_ship,
            };
            // Check with Jhony if there's a better way to overwrite destinationZip this updates destinationZip if there's a dropship address
            state.freightRater = {
              ...state.freightRater,
              destinationZip: dSAZipCode,
              destinationState: dSAState,
            };
          }
        }

        if (action.payload?.subOrderData?.third_party_billing) {
          const { third_party_billing } = action.payload.subOrderData;
          state.thirdPartyBilling = {
            ...third_party_billing,
            zipCode: third_party_billing.zip_code,
          };
        }
      })
      .addCase(updateSubOrder.fulfilled, (state, action) => {
        state.handlingCharge =
          action.payload.updatedSubOrder.freight.handling_charge;
      })
      .addCase(getWarehouseInformation.fulfilled, (state, action) => {
        state.freightRater = {
          ...state.freightRater,
          warehouseNumber: action.payload.warehouse_id,
          longName: action.payload.long_name,
          warehouseState: action.payload.name,
          czarZip: action.payload.czar_zip,
        };
      })
      .addCase(resetSubOrderId, () => {
        return initialState;
      })
      .addCase(setCustomerId, (state) => {
        return {
          ...initialState,
          weight: state.weight,
        };
      })
      .addCase(setCleaningAll, () => {
        return initialState;
      })
      .addCase(getQuote.fulfilled, (state, action) => {
        if (action.payload?.quote?.customer) {
          const { prepaid_weight } = action.payload.quote.customer;

          if (prepaid_weight === 0) state.prepaidWeight = PREPAID_WEIGHT;
          if (Number(prepaid_weight) > 0) state.prepaidWeight = prepaid_weight;
        }

        if (action.payload?.quote?.freight) {
          const {
            bill_of_lading,
            freight_line,
            freight_amount,
            freight_line: { handling_charge },
            is_direct_ship,
            prepaid,
            is_drop_ship,
            drop_ship_address_id,
            estimated_cost,
            actual_cost,
            blind_ship,
            freightline_quote_num,
            tms_quote_num,
          } = action.payload.quote.freight;

          state.billOfLading = bill_of_lading;
          state.freightLine = {
            id: freight_line.freight_line,
            title: freight_line.name,
          };
          state.freightAmount = freight_amount;
          state.handlingCharge = handling_charge;
          state.isDirectShip = is_direct_ship;
          state.prepaid = prepaid;
          state.estimatedCost = estimated_cost;
          state.actualCost = actual_cost;
          state.freightRater = {
            ...state.freightRater,
            carrierQuoteNumber: freightline_quote_num,
            TMSQuoteNumber: tms_quote_num,
          };
          state.blindShip = blind_ship;

          if (is_drop_ship) {
            const {
              drop_ship_address: {
                ship_to_name,
                address: {
                  street: dSAStreet,
                  route: dSARoute,
                  city: dSACity,
                  state: dSAState,
                  zip_code: dSAZipCode,
                },
                contact: {
                  email: dSAEmail,
                  fax: dSAFax,
                  name: dSAName,
                  phone: dSAPhone,
                },
              },
            } = action.payload.quote.freight;

            state.dropShipAddress = {
              ...state.dropShipAddress,
              id: drop_ship_address_id,
              name: ship_to_name,
              street: dSAStreet,
              route: dSARoute,
              city: dSACity,
              state: dSAState,
              zipCode: dSAZipCode,
              email: dSAEmail,
              fax: dSAFax,
              contactName: dSAName,
              phone: dSAPhone,
              isDropShip: is_drop_ship,
            };
          }
        }
      })
      .addCase(verifyFreightDetails.pending, (state) => {
        state.loadingFreightDetails = true;
      })
      .addCase(verifyFreightDetails.fulfilled, (state, action) => {
        const freightData = refillFreightData({
          data: action.payload.freightDetails,
        });

        if (action.payload.freightLine) {
          state.freightLine = {
            id: action.payload.freightLine.freight_line_num,
            title: `${action.payload.freightLine.freight_line_num} - ${action.payload.freightLine.name}`,
          };

          state.freightRater.TMSQuoteNumber = "";
          state.freightRater.carrierQuoteNumber = "";
          state.freightRater.rated = false;
        }

        state.prepaidCode = freightData.prepaidCode;
        state.prepaid = freightData.prepaid;
        state.loadingFreightDetails = false;
      })
      .addCase(verifyFreightDetails.rejected, (state) => {
        state.loadingFreightDetails = false;
      })
      .addCase(applyShippingRules.fulfilled, (state, action) => {
        if (action.payload.status === "Success") {
          state.freightLine = {
            id: action.payload.freightLine?.freight_line_num,
            title: action.payload.freightLine
              ? `${action.payload.freightLine.freight_line_num} - ${action.payload.freightLine.name}`
              : "",
          };
          state.prepaidCode = action.payload.prepaidCode;
          state.prepaid = action.payload.prepaid;
          state.thirdPartyBilling = action.payload.thirdPartyBilling;
        }
      })
      .addCase(getFreightLineList.fulfilled, (state, action) => {
        const freightLines = action.payload.map((line) => {
          return {
            id: line.freight_line_num,
            title: `${line.freight_line_num} - ${line.name}`,
          };
        });
        state.availableFreightLines = freightLines;
      })
      .addCase(setCustomerData, (state, action) => {
        if (action?.payload?.shippingZipCode) {
          state.freightRater.destinationZip = action.payload.shippingZipCode;
        }

        if (action?.payload?.mainState) {
          state.freightRater.destinationState = action.payload.mainState;
        }

        if (action.payload?.prepaidWeight === 0)
          state.prepaidWeight = PREPAID_WEIGHT;
        if (Number(action.payload?.prepaidWeight) > 0)
          state.prepaidWeight = action.payload?.prepaidWeight;

        if (action.payload?.aftermarketBillingAddress) {
          state.thirdPartyBilling.name = action.payload.aftermarketName;
          state.thirdPartyBilling.zipCode =
            action.payload.aftermarketBillingAddress?.zip_code;
          state.thirdPartyBilling.city =
            action.payload.aftermarketBillingAddress?.city;
          state.thirdPartyBilling.state =
            action.payload.aftermarketBillingAddress?.state;
          state.thirdPartydr1 = action.payload.aftermarketBillingAddress?.route;
          state.thirdPartyBilling.addr2 =
            action.payload.aftermarketBillingAddress?.street;
        }
      })
      .addCase(getFreightLinesByScacCode.fulfilled, (state, action) => {
        if (action.payload) {
          state.freightLine = {
            id: action.payload.freight_line_num,
            title: `${action.payload.freight_line_num} - ${action.payload.name}`,
          };
        }
      })
      .addCase(getCustomerById.fulfilled, (state, action) => {
        if (action.payload?.prepaidWeight === 0)
          state.prepaidWeight = PREPAID_WEIGHT;
        if (Number(action.payload?.prepaidWeight) > 0)
          state.prepaidWeight = action.payload?.prepaidWeight;
      })
      .addCase(getRateLTLOptions.fulfilled, (state, action) => {
        if (action.payload) {
          state.freightRater.rateLTLOptions = action.payload;
        }
      })
      .addCase(getPackageRaterOptions.fulfilled, (state, action) => {
        if (action.payload.packageRaterAllOptions) {
          state.freightRater.packageRaterOptions =
            action.payload.packageRaterAllOptions;
        }
        state.freightRater.upsStatus = action.payload.upsStatus;
        state.freightRater.fedexStatus = action.payload.fedexStatus;
      })
      .addCase(searchCustomersById.fulfilled, (state, action) => {
        console.log("action.payload", action.payload);
        if (action.payload) {
          state.thirdPartyBilling.name = action.payload.data[0].aftermarketName;
          state.thirdPartyBilling.zipCode =
            action.payload.data[0].aftermarketBillingAddress?.zip_code;
          state.thirdPartyBilling.city =
            action.payload.data[0].aftermarketBillingAddress?.city;
          state.thirdPartyBilling.state =
            action.payload.data[0].aftermarketBillingAddress?.state;
          state.thirdPartyBilling.addr1 =
            action.payload.data[0].aftermarketBillingAddress?.route;
          state.thirdPartyBilling.addr2 =
            action.payload.data[0].aftermarketBillingAddress?.street;
        }
      })
      .addCase(getBolAppendable.fulfilled, (state, action) => {
        state.billAppendable = action.payload;
      })
      .addCase(getBolDetail.fulfilled, (state, action) => {
        state.bolDetail = action.payload;
      })
      .addCase(getAppendedSubOrdersInfo.pending, (state, action) => {
        state.appendedSubOrders.loading = true;
      })
      .addCase(getAppendedSubOrdersInfo.fulfilled, (state, action) => {
        state.appendedSubOrders.loading = false;
        state.appendedSubOrders.data = action.payload;
      });
  },
});

export const {
  setFreight,
  setPrepaid,
  setThirdPartyBilling,
  deleteDropShip,
  toggleDropShipPopupState,
  toggleFreightRaterPopupState,
  setDropShipInformation,
  setSelectedDropShip,
  setFreightRater,
  setFreightLine,
  setFreightPackages,
  setSelectedRateLTLOption,
  setSelectedPackageRaterOption,
} = freightSlice.actions;
export default freightSlice.reducer;
